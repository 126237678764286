import './overlay.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { useDivRef } from '../hooks';
import { useEffect, useRef, useState } from 'react';
import { soundOff, soundOn } from '../reducers/soundReducer';
import { Howl } from 'howler';

export const Overlay = () => {
    const dispatch = useDispatch()

    // ============================
    //      Overlay References
    // ============================
    // These are refs used to control the overlay and inner elements within the overlay
    const overlay = useDivRef()
    const { group, group2, group3, group4 } = { group: useDivRef(), group2: useDivRef(), group3: useDivRef(), group4: useDivRef() }
    const skills = useDivRef()
    const { sound, soundUnmute, soundMute } = { sound: useRef<Howl | null>(null), soundUnmute: useDivRef(), soundMute: useDivRef() }

    // ============================
    //      State Selectors
    // ============================
    // Hooks for selecting state from the Redux store
    const statusState = useSelector((state: RootState) => state.loading.status)
    const soundState = useSelector((state: RootState) => state.sound.status)

    const [skillCategoryHelper, setSkillCategoryHelper] = useState<SkillCategoryHelper | null>(null)
    useEffect(() => {
        // ============================
        //      Overlay Visibility
        // ============================
        // @desc Triggers the visibility of the overlay and animates
        //       the opacity and position of the brand, subtitle,
        //       information, and sound elements upon successful status.
        if (statusState === "succeeded") {
            const overlayHelper = new OverlayHelper(overlay, [group, group2, group3, group4])
            overlayHelper.init()

            const newSkillCategoryHelper = new SkillCategoryHelper(skills, ["LANGUAGES", "FRAMEWORKS", "DATABASES", "TOOLS & PLATFORMS", "OPERATING SYSTEMS", "WEB SERVERS"])
            setSkillCategoryHelper(newSkillCategoryHelper)

            /*
            // ============================
            //      Skills
            // ============================
            // This section manages the skills using SkillCategoryHelper.
            const newSkillCategoryHelper = new SkillCategoryHelper(skills, ["LANGUAGES", "FRAMEWORKS", "DATABASES", "TOOLS & PLATFORMS", "OPERATING SYSTEMS", "WEB SERVERS"])
            setSkillCategoryHelper(newSkillCategoryHelper)
            newSkillCategoryHelper.removeAllCategories()
            let root = document.getElementById("root")
            let topLeft = document.getElementById("top-left")

            root!.onclick = (event) => {
                if (!topLeft?.contains(event.target as Node)) {
                    newSkillCategoryHelper.hide()
                }
            }
            */

            let root = document.getElementById("root")
            let topLeft = document.getElementById("top-left")
            root!.onclick = (event) => {
                if (!topLeft?.contains(event.target as Node)) {
                    newSkillCategoryHelper.hide()
                }
            }
        }
    }, [statusState])

    /*
    useEffect(() => {
        if (skillCategoryHelper) {
            skillCategoryHelper.construct();
            skillCategoryHelper.addSkills("LANGUAGES", ["TYPESCRIPT", "JAVASCRIPT", "JAVA", "KOTLIN", "C#", "PHP", "RUST"])
            skillCategoryHelper.addSkills("FRAMEWORKS", ["NODE.JS", "DENO.JS", ".NET", "REACT", "NEXT.JS", "VUE.JS", "SVELTE", "SVELTEKIT", "BLAZOR"])
            skillCategoryHelper.addSkills("DATABASES", ["POSTGRES", "MYSQL", "REDIS", "MONGODB", "DYNAMODB", "FIREBASE", "ELASTICSEARCH"])
            skillCategoryHelper.addSkills("TOOLS & PLATFORMS", ["DOCKER", "AWS", "AZURE", "HIBERNATE", "PRISMA", "TYPEORM"])
            skillCategoryHelper.addSkills("OPERATING SYSTEMS", ["UBUNTU", "CENT OS", "DEBIAN"])
            skillCategoryHelper.addSkills("WEB SERVERS", ["NGINX", "APACHE"])
        }
        return () => {
            if (skillCategoryHelper) {
                skillCategoryHelper.removeAllCategories()
            }
        }
    }, [skillCategoryHelper])
    */

    // ============================
    //      Sound Toggle
    // ============================
    // Togglable for sound
    const toggleSound = () => {
        if (soundState === "off") {
            dispatch(soundOn())
            if (soundUnmute.current && soundMute.current) {
                let unmute = soundUnmute.current
                let mute = soundMute.current
                unmute.style.left = "0"
                unmute.style.opacity = "1"
                unmute.style.visibility = "visible"
                mute.style.visibility = "hidden"
                mute.style.opacity = "0"
                mute.style.left = "-3px"
                sound.current = new Howl({
                    src: ['/sound/amb_void_loop_3.wav'],
                    volume: 0,
                    loop: true,
                    onload: () => {
                        if (sound.current) {
                            sound.current.fade(0, 1, 2000)
                            sound.current.play()
                        }
                    },
                })
            }
        } else {
            dispatch(soundOff())
            if (soundMute.current && soundUnmute.current) {
                let mute = soundMute.current
                let unmute = soundUnmute.current
                mute.style.left = "0"
                mute.style.opacity = "1"
                mute.style.visibility = "visible"
                unmute.style.visibility = "hidden"
                unmute.style.opacity = "0"
                unmute.style.left = "-3px"
                if (sound.current) {
                    sound.current.fade(1, 0, 2000)
                }
            }
        }
    }

    return (
        <div id='overlay' ref={overlay} className="overlay">
            <div className='stats'></div>
            <div onClick={() => { skillCategoryHelper!.show() }} onMouseOver={() => { skillCategoryHelper!.show() }} id='top-left' className='top-left'>
                <div ref={group} className='group brand'>
                    <div className='avatar'></div>
                    <div className='mini'>
                        <div className='title'>ZELJKO VRANJES</div>
                        <div className='subtitle'>PORTFOLIO 2024</div>
                        <div ref={skills} className='skills'>
                            <div className='skill'>GITHUB
                                <div style={{ float: 'right' }} className=''><a href='https://github.com/chomnr'>chomnr</a></div>
                            </div>
                            <div className='skill'>BLOG
                                <div style={{ float: 'right' }} className=''><a href='https://blog.zeljkovranjes.com/'>personal</a></div>
                            </div>
                            <div className='skill'>RESUME
                                <div style={{ float: 'right' }} className=''><a href='https://1drv.ms/b/c/9272f79153ef8c94/EanePUdL-D1MprGgnXT7-xcB3kF7zHfj8KY-r6zgq9Xt-Q?e=mzTD8x'>latest</a></div>
                            </div>
                            {/*div className='divider'>&nbsp;</div>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-left'>
                <div ref={group3} className='group info'>
                    {/*
                        <div className='info'>
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="white" stroke-width="1" stroke-linecap="round" stroke-linejoin="miter"><rect x="2" y="2" width="20" height="20" rx="0"></rect><line x1="7" y1="8" x2="17" y2="8"></line><line x1="7" y1="12" x2="17" y2="12"></line><line x1="7" y1="16" x2="12" y2="16"></line></svg>
                        </div>
                    */}
                </div>
            </div>
            <div className='top-right'>
            </div>
            <div className='bottom-right'>
                <div ref={group2} className='group sound'>
                    <div onClick={() => toggleSound()} className='speaker'>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="white" strokeWidth="1">
                            <polygon points="2 8 2 16 6 16 13 21 13 3 6 8 2 8"></polygon>
                        </svg>
                    </div>
                    <div ref={soundMute} className='mute'>
                        <div className='one'></div>
                        <div className='two'></div>
                    </div>
                    <div ref={soundUnmute} className='unmute'>
                        <div className='one'></div>
                        <div className='two'></div>
                        <div className='three'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class OverlayHelper {
    overlay: React.RefObject<HTMLDivElement>
    groups: React.RefObject<HTMLDivElement>[]
    initialDelay: number
    subsequentDelay: number

    constructor(overlay: React.RefObject<HTMLDivElement>,
        groups: React.RefObject<HTMLDivElement>[],
        initialDelay: number = 1000,
        subsequentDelay: number = 200) {
        this.overlay = overlay;
        this.groups = groups;
        this.initialDelay = initialDelay
        this.subsequentDelay = subsequentDelay
    }

    public init() {
        setTimeout(() => {
            if (this.overlay.current) {
                let overlay_current = this.overlay.current
                this.show(overlay_current)
                setTimeout(() => {
                    this.showBulk(this.groups)
                }, this.subsequentDelay)
            }
        }, this.initialDelay)
    }

    show(div: HTMLDivElement) {
        if (div) {
            if (div.id === "overlay") {
                div.style.visibility = "visible"
            } else {
                div.style.opacity = "1"
                if (div.parentElement?.className === "top-left") {
                    div.style.right = "0"
                }
                if (div.parentElement?.className === "top-right") {
                    div.style.left = "0"
                }
                if (div.parentElement?.className === "bottom-left") {
                    div.style.right = "0"
                }
                if (div.parentElement?.className === "bottom-right") {
                    div.style.left = "0"
                }
            }
        }
    }

    showBulk(group: React.RefObject<HTMLDivElement>[]) {
        group.forEach((group) => {
            if (group.current) {
                let current = group.current;
                if (current.id !== "overlay") {
                    this.show(current)
                }
            }
        })
    }
}

interface CategoryElement {
    name: string,
    element: HTMLDivElement,
    skills: HTMLDivElement
}

class SkillCategoryHelper {
    site: React.RefObject<HTMLDivElement>
    categories: string[]
    categoriesElement: CategoryElement[]

    constructor(site: React.RefObject<HTMLDivElement>, categories: string[]) {
        this.site = site
        this.categories = categories
        this.categoriesElement = []
    }

    public construct() {
        this.categories.forEach((category) => this.createCategory(category))
    }

    public show() {
        if (this.site && this.site.current) {
            let current = this.site.current
            current.style.opacity = "1"
            current.style.visibility = "visible"
            current.style.bottom = "-10px"
        }
    }

    public hide() {
        if (this.site && this.site.current) {
            let current = this.site.current
            current.style.opacity = "0"
            current.style.bottom = "0px"
            setTimeout(() => {
                current.style.visibility = "hidden"
                let categories = current.querySelectorAll(".skill-category")
                categories.forEach((categoryNode) => {
                    let category = categoryNode as HTMLDivElement
                    let title = category.querySelector(".title") as HTMLDivElement
                    let dropDown = title.querySelector(".dropdown") as HTMLDivElement
                    let skillList = category.querySelector(".skill-list") as HTMLDivElement
                    skillList.style.display = "none"
                    dropDown.textContent = "+"
                })
            }, 250)
        }
    }

    public addSkills(categoryName: string, skillNames: string[]) {
        skillNames.forEach((skill) => {
            this.addSkill(categoryName, skill)
        })
    }

    private createCategory(categoryName: string) {
        const current = this.site?.current
        if (!current) return

        const category = document.createElement('div')
        category.classList.add('skill-category')

        const title = document.createElement('div')
        title.classList.add('title');
        title.innerHTML = categoryName;

        const dropDown = document.createElement('div')
        dropDown.textContent = '+'
        dropDown.classList.add('dropdown')
        dropDown.style.float = "right"

        const skillList = document.createElement('div')
        skillList.classList.add('skill-list')
        skillList.style.display = "none"

        title.appendChild(dropDown)

        category.appendChild(title)
        category.appendChild(skillList)

        current.appendChild(category)

        dropDown.addEventListener('click', () => {
            if (skillList.style.display === "none") {
                skillList.style.display = "flex"
                dropDown.textContent = '-'
            } else {
                skillList.style.display = "none"
                dropDown.textContent = '+'
            }
        });

        this.categoriesElement.push({
            name: categoryName,
            element: category,
            skills: skillList
        });
    }


    private addSkill(categoryName: string, skillName: string) {
        const current = this.site?.current
        if (!current) return
        const category = this.categoriesElement.find(category => category.name === categoryName)
        if (!category) {
            console.error(`Category ${categoryName} not found`)
            return;
        }
        const element = category.element
        let skillList = element.querySelector(".skill-list") as HTMLDivElement
        if (!skillList) {
            console.error(`Skill-list for ${categoryName} not found`)
            return
        }
        let skill = document.createElement("div")
        skill.classList.add("skill")
        skill.textContent = skillName
        skillList.appendChild(skill)
    }

    public removeAllCategories() {
        const current = this.site?.current
        if (!current) return
        this.categoriesElement.forEach(category => {
            category.element.remove()
        })
    }
}