import { DepthOfField, Bloom, Vignette, ChromaticAberration, Noise, EffectComposer, Sepia, Glitch, } from "@react-three/postprocessing";
import { Vector2 } from "three";
import { GlitchEffect } from "postprocessing";
import { useEffect, useRef } from "react";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { delay } from "../shorthand";

export function Effect() {
  const glitchRef = useRef<GlitchEffect>(null)
  const status = useSelector((state: RootState) => state.phase.status)

  useEffect(() => {
    let glitch = glitchRef.current
    if (glitch) {
      if (status === "RobotAware") {
        glitch.minStrength = 0.2
        glitch.maxStrength = 0.5
        glitch.minDuration = 0.2
        glitch.maxDuration = 0.5
        glitch.ratio = 0.53;
        // revert to default glitch with slight change
        delay(() => {
          if (glitch) {
            glitch.minStrength = 0.03
            glitch.maxStrength = 0.03
            glitch.minDuration = 0.03
            glitch.maxDuration = 0.03
          }
        }, 4000)
      }

      if (status === "RobotAngry") { 
        // also hide robot after glitch
        glitch.minStrength = 1
        glitch.maxStrength = 5
        glitch.minDuration = 1
        glitch.maxDuration = 2
        glitch.ratio = 0.70
        // temp remove delay robot will hide...
        delay(() => {
          if (glitch) {
            glitch.minStrength = 0.07
            glitch.maxStrength = 0.07
            glitch.minDuration = 0.07
            glitch.maxDuration = 0.07
          }
        }, 2000)
      }
      if (status === "RobotDisappear") { 
        glitch.minStrength = 0
        glitch.maxStrength = 0
        glitch.minDuration = 0
        glitch.maxDuration = 0
        glitch.ratio = 0
      }
    }
  }, [status])
  return (
    <EffectComposer>
      <DepthOfField
        focusDistance={0.5}
        focalLength={0.05}
        bokehScale={1.5}
        height={480}
      />
      <Bloom luminanceThreshold={0.3} luminanceSmoothing={0.8} height={300} />
      <Sepia intensity={1.9} />
      <Vignette eskil={false} offset={0.2} darkness={1.5} />
      <ChromaticAberration
        offset={new Vector2(0.002, 0.002)}
        radialModulation={true}
        modulationOffset={0}
      />
      <Glitch ref={glitchRef} delay={new Vector2(0.3, 0.3)} />
      <Noise opacity={0.05} />
    </EffectComposer>
  );
}