import './App.css';
import { Loading } from './component/loading';
import { Overlay } from './component/overlay';
import { Canvas, extend, useFrame } from '@react-three/fiber';
import { CameraControls, Environment, FirstPersonControls, Lightformer, MeshReflectorMaterial, OrbitControls, PointerLockControls } from '@react-three/drei';
import { Robot } from './model/Robot';
import { Effect } from './component/effect';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import { useEffect, useRef } from 'react';
import { Euler, Group, PerspectiveCamera, Vector3 } from 'three';

extend(CameraControls)

function App() {
  const phaseStatus = useSelector((state: RootState) => state.phase.status)
  return (
    <>
      <Canvas gl={{ antialias: false }} dpr={[1, 1.15]} camera={{ position: [0, 0, 0], fov: 90 }} shadows>
      <><Scene /></>
        <group position={[0, -1, 0]} rotation={new Euler(0, 0, 0)}>
          <Robot position={[0, 0, 0]} />
        </group>
        <Effect />
      </Canvas>
      <Loading />
      <Overlay />
    </>
  )
}



function Scene() {
  const groupRef = useRef<Group>(null!)
  const loadingStatus = useSelector((state: RootState) => state.loading.status)
  const phaseStatus = useSelector((state: RootState) => state.phase.status)

  const cameraRef = useRef<CameraControls | null>(null)

  useEffect(() => {
    if (loadingStatus === "succeeded" && cameraRef.current) {
      const camera = cameraRef.current

      setTimeout(() => {
        camera.setPosition(0, 3.4, 0.5, true)
        camera.rotate(5.4, 1, false)
        camera.update(0)
      }, 400)
    }
  }, [loadingStatus])
  return (
    <>
      <CameraControls
        ref={cameraRef}
        enabled={phaseStatus !== 'RobotDisappear'}
        azimuthAngle={0}
        polarAngle={0}
        azimuthRotateSpeed={0}
        polarRotateSpeed={0}
        dollySpeed={0}
        truckSpeed={0}
      />
      <color
        attach={"background"}
        args={["#0a0a0a"]}
      />
      <group ref={groupRef} position={[0, -1, 0]} rotation={new Euler(0, 0, 0)}>
        <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]}>
          <planeGeometry args={[50, 50]} />
          <MeshReflectorMaterial
            blur={[300, 30]}
            resolution={2048}
            mixBlur={1}
            mixStrength={180}
            roughness={1}
            depthScale={1.2}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.4}
            color="#202020"
            metalness={0.8}
            mirror={0}
          />
        </mesh>
        <Environment resolution={512}>
          <Lightformer
            intensity={2}
            rotation-x={Math.PI / 2}
            position={[0, 4, -9]}
            scale={[10, 1, 1]}
          />
          <Lightformer
            intensity={2}
            rotation-x={Math.PI / 2}
            position={[0, 4, 0]}
            scale={[10, 1, 1]}
          />
          <Lightformer
            intensity={2}
            rotation-y={Math.PI / 2}
            position={[-50, 2, 0]}
            scale={[100, 2, 1]}
          />
          <Lightformer
            intensity={2}
            rotation-y={-Math.PI / 2}
            position={[50, 2, 0]}
            scale={[100, 2, 1]}
          />
        </Environment>
      </group>
    </>
  )
}

export default App


  /*
  useEffect(() => {
    if (controlsRef.current) {
      const controls = controlsRef.current;
      
      controls.minDistance = 0;
      controls.maxDistance = 0;

      controls.dollyToCursor = false;
      controls.mouseButtons.wheel = 16; // CameraControls.ACTION.ZOOM
      controls.touches.two = 8192; // CameraControls.ACTION.TOUCH_ZOOM_TRUCK

      controls.minPolarAngle = Math.PI / 3;
      controls.maxPolarAngle = (2 * Math.PI) / 3;

      controls.azimuthRotateSpeed = -0.3;
      controls.polarRotateSpeed = -0.3;
      
      controls.saveState();
    }
  }, []);

  useFrame((state) => {
    const delta = state.clock.getDelta();
    const controls = controlsRef.current;
    if (controls) {
      controls.update(delta);
    }
  });
  */


// Define yaw limits in radians (e.g., -45 to 45 degrees)
// Define yaw limits in radians (e.g., -45 to 45 degrees)


//useFrame(() => {
//  if (controlsRef.current) {
/*
// Get the current horizontal angle (lon) from controls
const currentYaw = controlsRef.current.lon;

// Clamp the horizontal rotation within limits
if (currentYaw < yawLimits.min) {
  controlsRef.current.lon = yawLimits.min; // Clamp to minimum
} else if (currentYaw > yawLimits.max) {
  controlsRef.current.lon = yawLimits.max; // Clamp to maximum
}

// Force update the controls to apply the changes
controlsRef.current.update(0); // Pass the delta time as needed (0 for immediate update)
*/
//  }
//});

/*
const yawLimits = { min: -45, max: 45 };
 
useFrame(() => {
  if (controlsRef.current) {
    // Get current longitude (yaw) from controls
    const currentLon = controlsRef.current.lon;

    // Clamp the longitude within limits
    if (currentLon < yawLimits.min) {
      controlsRef.current.lon = yawLimits.min; // Clamp to minimum
    } else if (currentLon > yawLimits.max) {
      controlsRef.current.lon = yawLimits.max; // Clamp to maximum
    }
  }
});
*/

/*
const yawLimits = { min: -90, max: 180 };
useEffect(() => {
  if (controlsRef.current) {
    const { rotation } = controlsRef.current.object; // 
    controlsRef.current.lon = 10; // Convert radians to degrees
    controlsRef.current.lat = 10; // Convert radians to degrees
  }
}, []);

useFrame(() => {
  if (controlsRef.current) {
    // Get current longitude (yaw) from controls
    const currentLon = controlsRef.current.lon;

    // Clamp the longitude within limits
    if (currentLon < yawLimits.min) {
      controlsRef.current.lon = yawLimits.min; // Clamp to minimum
    } else if (currentLon > yawLimits.max) {
      controlsRef.current.lon = yawLimits.max; // Clamp to maximum
    }
  }
});
*/



{/*
<mesh>
        <boxGeometry args={[1, 1, 1]} />
        <meshStandardMaterial color="orange" />
      </mesh>


  
  <FirstPersonControls
          enabled={true}
          movementSpeed={0}
          lookSpeed={1}
          verticalMin={-60}
          verticalMax={60}
          lookVertical={true}
          activeLook={true}
        />


      <group>

        <Robot ref={modelRef} />
        <perspectiveCamera ref={cameraRef} position={[0, 0, 5]} fov={75} />
        <FirstPersonControls
          enabled={true}
          movementSpeed={0}
          lookSpeed={1}
        />
      </group>
    */}
