import { createAction, createReducer } from '@reduxjs/toolkit';

/**
 * Interface representing the phase state of the application.
 * 
 * @property {'idle' | 'RobotAnnoyed' | 'RobotAngry'} status - The current phase of the application:
 *   - 'idle': No phase is active.
 *   - 'RobotAware': The phase is set to RobotAware.
 *   - 'RobotAnnoyed': The phase is set to RobotAnnoyed.
 *   - 'RobotAngry': The phase is set to RobotAngry.
 */

export interface PhaseState {
    status: 'idle' | 'RobotAware' | 'RobotAnnoyed' | 'RobotAngry' | 'RobotDisappear'
}

/** 
 * Initial phase state object for the application.
 * - `progress`: Starts at 0, indicating no progress.
 * - `status`: Initially set to 'idle', representing no active phase.
 */

const defaultPhaseState: PhaseState = {
    status: 'idle'
};

/** 
 * Action Creators for phase actions.
 */

export const startRobotIdle = createAction('phase/idle')
export const startRobotAware = createAction('phase/RobotAware')
export const startRobotAnnoyed = createAction('phase/RobotAnnoyed')
export const startRobotAngry = createAction('phase/RobotAngry')
export const startRobotDisappear = createAction('phase/RobotDisappear')


/** 
 * Phase Reducer
 * 
 * Manages the phase state of the application, updating the status 
 * - 'RobotAware' when the phase is set to RobotAware.
 * - 'RobotAnnoyed' when the phase is set to RobotAnnoyed.
 * - 'RobotAngry' when the phase is set to RobotAngry.
 */

export const phaseReducer = createReducer(defaultPhaseState, (builder) => {
    builder
        .addCase(startRobotIdle, (state) => {
            state.status = 'idle'
        })
        .addCase(startRobotAware, (state) => {
            state.status = 'RobotAware'
        })
        .addCase(startRobotAnnoyed, (state) => {
            state.status = 'RobotAnnoyed'
        })
        .addCase(startRobotAngry, (state) => {
            state.status = 'RobotAngry'
        })
        .addCase(startRobotDisappear, (state) => {
            state.status = 'RobotDisappear'
        })
});

export default phaseReducer