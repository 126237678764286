import { createAction, createReducer } from '@reduxjs/toolkit';

/**
 * Interface representing the sound state of the application.
 * 
 * @property {'on' | 'off' | 'succeeded' | 'failed'} status - The current sound status:
 *   - 'on': Sound is enabled.
 *   - 'off': Sound is disabled.
 */
export interface SoundState {
    status: 'on' | 'off' | 'succeeded' | 'failed';
}

/** 
 * Initial sound state object for the application.
 * - `status`: Initially set to 'off', representing that the sound is disabled.
 */
const defaultSoundState: SoundState = {
    status: 'off',
};

/** 
 * Action Creators for sound actions.
 */
export const soundOn = createAction('sound/on');
export const soundOff = createAction('sound/off');

/** 
 * Sound Reducer
 * 
 * Manages the sound state of the application, updating the status 
 * based on sound actions:
 * - 'on' when the sound is enabled
 * - 'off' when the sound is disabled
 */
export const soundReducer = createReducer(defaultSoundState, (builder) => {
    builder
        .addCase(soundOn, (state) => {
            state.status = 'on';
        })
        .addCase(soundOff, (state) => {
            state.status = 'off';
        })
});

export default soundReducer;