import './loading.css';
import { useDispatch } from 'react-redux';
import { forwardRef, useEffect, useState } from 'react';
import { useDivRef, useLoading } from '../hooks';
import { useProgress } from '@react-three/drei';
import { loadingSet, loadingStart, loadingSucceeded } from '../reducers/loadingReducer';

export const Loading = () => {
    const dispatch = useDispatch()
    const [main, middle, loader] = [useDivRef(), useDivRef(), useDivRef()]
    const [loading, progress] = [useLoading(), useProgress()]
    useEffect(() => {
        dispatch(loadingStart())
        if (progress.progress === 100) {
            dispatch(loadingSet(100))
        }
        if (loading.status !== "succeeded" && loading.status !== "idle" && progress.progress === 100) {
            setTimeout(() => {
                if (main.current && middle.current && loader.current) {
                    middle.current.style.transform = 'scale(1.1)'
                    loader.current.style.opacity = '0'
                    setTimeout(() => main.current!.style.display = "none", 500)
                    dispatch(loadingSucceeded())
                }
            }, 2400)
        }
    }, [progress])
    return (
        <div ref={main} className="loading">
            <div ref={middle} className="middle">
                <Loader ref={loader} progress={loading.progress} />
            </div>
        </div>
    );
}


const Loader = forwardRef<HTMLDivElement, { progress: number }>(({ progress }, ref) => {
    const [delayedProgress, setDelayedProgress] = useState([0, 0, 0, 0])
    useEffect(() => {
        const delays = [500, 1000, 1500, 2000]
        const thresholds = [25, 50, 75, 100]
        if (progress === 100) {
            thresholds.forEach((threshold, index) => {
                setTimeout(() => {
                    setDelayedProgress(prev =>
                        prev.map((p, i) =>
                            i === index ? 100 : p
                        )
                    );
                }, delays[index])
            });
        }
        return () => delays.forEach(clearTimeout)
    }, [progress]);

    return (
        <div ref={ref} className="loader">
            {delayedProgress.map((p, i) => (
                <div
                    key={i}
                    style={{ [i % 2 === 0 ? 'width' : 'height']: `${p}%` }}
                    className={`segment-${i + 1}`}
                />
            ))}
        </div>
    );
});