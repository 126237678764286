import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';

/**
 * Interface representing the loading state of the application.
 * 
 * @property {number} progress - The current loading progress, ranging from 0 to 100.
 * @property {'idle' | 'loading' | 'succeeded' | 'failed'} status - The current status of the loading process:
 *   - 'idle': No loading activity.
 *   - 'loading': The loading process is ongoing.
 *   - 'succeeded': The loading process completed successfully.
 *   - 'failed': The loading process encountered an error.
 */

export interface LoadingState {
    progress: number
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
}

/** 
 * Initial loading state object for the application.
 * - `progress`: Starts at 0, indicating no progress.
 * - `status`: Initially set to 'idle', representing no loading activity.
 */

const defaultLoadingState: LoadingState = {
    progress: 0,
    status: 'idle'
};

/** 
 * Action Creators for loading actions.
 */

export const loadingStart = createAction('loading/idle')
export const loadingSucceeded = createAction('loading/succeeded')
export const loadingFailed = createAction('loading/failed')
export const loadingIncrement = createAction<number>('loading/increment')
export const loadingSet = createAction<number>('loading/set')


/** 
 * Loading Reducer
 * 
 * Manages the loading state of the application, updating the status 
 * and progress based on loading actions:
 * - 'loading' when the process begins
 * - 'succeeded' when the process completes successfully
 * - 'failed' when the process encounters an error
 */

export const loadingReducer = createReducer(defaultLoadingState, (builder) => {
    builder
        .addCase(loadingStart, (state) => {
            state.status = 'loading'
            state.progress = 0
        })
        .addCase(loadingSucceeded, (state) => {
            state.status = 'succeeded'
            state.progress = 100
        })
        .addCase(loadingFailed, (state) => {
            state.status = 'failed'
            state.progress = 0
        })
        .addCase(loadingIncrement, (state, action: PayloadAction<number>) => {
            state.progress = Math.min(state.progress + action.payload, 100)
        })
        .addCase(loadingSet, (state, action: PayloadAction<number>) => {
            state.progress = Math.min(action.payload, 100)
        })
});

export default loadingReducer