import { useEffect, useRef, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { JSX } from 'react/jsx-runtime'
import { Group, MeshStandardMaterial, SkinnedMesh } from 'three'
import { useDispatch, useSelector } from 'react-redux'
import { startRobotAngry, startRobotAnnoyed, startRobotAware, startRobotDisappear, startRobotIdle } from '../reducers/phaseReducer'
import { RootState } from '../store'
import { delay } from '../shorthand'
import { useFrame } from '@react-three/fiber'

export function Robot(props: JSX.IntrinsicElements["group"]) {
  const dispatch = useDispatch()
  const status = useSelector((state: RootState) => state.phase.status)
  const group = useRef<Group>(null)
  const { nodes, materials, animations } = useGLTF('/robot.glb')
  const { actions } = useAnimations(animations, group)

  // robot color
  const robotShell = new MeshStandardMaterial({ color: "#121212", roughness: 0.8, metalness: 0.4 })
  const robotOrgan = new MeshStandardMaterial({ color: "#242424", roughness: 0.8, metalness: 0.4 })

  const action = actions["Animation_3"]

  useEffect(() => {
    const action = actions["Animation_3"]
    if (action) {
      action.play()
      action.paused = true
    }
  }, [])

  const [annoyance, setAnnoyance] = useState(0)
  const [isAnimationPlaying, setIsAnimationPlaying] = useState(false)
  /*
  const annoy = () => {
    if (status !== 'RobotAware' && status !== 'RobotAngry' && status !== 'RobotDisappear')
      setAnnoyance(annoyance + 1)
    console.log(annoyance)
  };*
  */

  const annoy = () => {
    if (!isAnimationPlaying) {
      setAnnoyance((prevAnnoyance) => prevAnnoyance + 1)
    }
  }

  useFrame(() => {
    if (action && !isAnimationPlaying && annoyance >= 2 && status === "idle") {
      setIsAnimationPlaying(true)
      setAnnoyance(2)
      action.paused = false
      action.timeScale = 1.3
      const checkAnimation = () => {
        if (action.time > 5.4) {
          action.paused = true
          dispatch(startRobotAware())
          delay(() => {
            action.paused = false
            delay(() => {
              action.paused = true
              dispatch(startRobotIdle())
              setAnnoyance(0)
              setIsAnimationPlaying(false)
              action.time = 0
            }, 3000)
          }, 2000)
        } else {
          requestAnimationFrame(checkAnimation)
        }
      }
      checkAnimation()
    }
  })
  
  

  /*
  useFrame(() => {
    if (action) {
      if (annoyance >= 2 && status === "idle") {
        action.paused = false
        action.timeScale = 1.3
        setAnnoyance(2)
        if (action.time > 5.4) {
          action.paused = true
          dispatch(startRobotAware())
          delay(() => {
            action.paused = false
            delay(() => {
              action.paused = true
              dispatch(startRobotIdle())
              setAnnoyance(0)
            }, 3000)
          }, 2000)
        }
      }
*/
      /*
      if (annoyance >= 5 && status === "RobotAnnoyed") {
        action.timeScale = 6
        action.paused = false
        setAnnoyance(5)
        if (action.time > 5.4) {
          action.paused = true
          dispatch(startRobotAngry())
          delay(() => {
            action.paused = false
            dispatch(startRobotDisappear())
            delay(() => {
              action.paused = true
              setAnnoyance(11)
            }, 3000)
          }, 1000)
        }
      }
      */
     /*
    }
  })

  useEffect(() => {
    if (status === "RobotDisappear") {
      if (group.current) {
        group.current.visible = false
        setTimeout(() => {
          if (action) {
            action.time = 0
            action.play()
            action.paused = true
            group.current!.visible = true
          }
        }, 500)
    */
        /*
        setTimeout(() => {
          if (action) {
            const mixer = action.getMixer()
            action.time = 0
            action.play()
            action.paused = false
            action.paused = true
            group.current!.visible = true
          }
        }, 500)
 
      }
    }
  }, [status])
  */
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group
          name="Plane001"
          position={[0.675, 0.514, 0]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={0.304}
        />
        <group name="CTRL_RIG">
          <group name="Armature001" position={[0, 0, -0.158]}>
            <group onClick={annoy} name="Cube002">
              <skinnedMesh
                name="Cube001_1"
                geometry={(nodes.Cube001_1 as SkinnedMesh).geometry}
                material={robotShell}
                skeleton={(nodes.Cube001_1 as SkinnedMesh).skeleton}
              />
              <skinnedMesh
                name="Cube001_2"
                geometry={(nodes.Cube001_2 as SkinnedMesh).geometry}
                material={robotOrgan}
                skeleton={(nodes.Cube001_2 as SkinnedMesh).skeleton}
              />
              <skinnedMesh
                name="Cube001_3"
                geometry={(nodes.Cube001_3 as SkinnedMesh).geometry}
                material={materials.Metal_Chain}
                skeleton={(nodes.Cube001_3 as SkinnedMesh).skeleton}
              />
            </group>
            <primitive object={nodes.Hip_1} />
            <primitive object={nodes.IkTarget_L_1} />
            <primitive object={nodes.ik_L_1} />
            <primitive object={nodes.IkTarget_R_1} />
            <primitive object={nodes.ik_R_1} />
          </group>
        </group>
        <mesh
          name="Chain_Right"
          castShadow
          receiveShadow
          geometry={(nodes.Chain_Right as SkinnedMesh).geometry}
          material={materials.Metal_Chain}
        />
        <mesh
          name="HookFloor"
          castShadow
          receiveShadow
          geometry={(nodes.HookFloor as SkinnedMesh).geometry}
          material={materials.Metal_Chain}
        />
        <mesh
          name="Chain_Right001"
          castShadow
          receiveShadow
          geometry={(nodes.Chain_Right001 as SkinnedMesh).geometry}
          material={materials.Metal_Chain}
          position={[0, 0, -0.15]}
          rotation={[-Math.PI, 0.032, -Math.PI]}
        />
        <group name="AIM_Chain" position={[-0.2, 0.463, -0.335]} rotation={[0.228, 0.533, 0.467]} />
      </group>
    </group>
  )
}

useGLTF.preload('/robot.glb')