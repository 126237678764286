import { useRef } from "react"
import { useSelector } from "react-redux"
import { RootState } from "./store"
import { LoadingState } from "./reducers/loadingReducer";

/**
 * Custom hook for accessing the loading state of the application.
 *
 * @returns {LoadingState} An LoadingState containing:
 * - {number} progress - The current loading progress, ranging from 0 to 100.
 * - {'idle' | 'loading' | 'succeeded' | 'failed'} status - The current status of the loading process:
 *   - 'idle': No loading activity.
 *   - 'loading': The loading process is ongoing.
 *   - 'succeeded': The loading process completed successfully.
 *   - 'failed': The loading process encountered an error.
 */
export const useLoading = (): LoadingState => {
    const progress = useSelector((state: RootState) => state.loading.progress);
    const status = useSelector((state: RootState) => state.loading.status);

    return { progress, status };
};

/**
 * Custom hook for creating a reference to a div element.
 *
 * @returns {React.RefObject<HTMLDivElement>} A ref object for a div element.
 */
export const useDivRef = (): React.RefObject<HTMLDivElement> => useRef<HTMLDivElement>(null)