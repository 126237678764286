import { configureStore } from "@reduxjs/toolkit";
import loadingReducer, { LoadingState } from "./reducers/loadingReducer";
import soundReducer, { SoundState } from "./reducers/soundReducer";
import phaseReducer, { PhaseState } from "./reducers/phaseReducer";

export const store = configureStore({
    reducer: {
        loading: loadingReducer,
        sound: soundReducer,
        phase: phaseReducer
    }
})

export interface RootState {
    loading: LoadingState,
    sound: SoundState,
    phase: PhaseState
}

export default store